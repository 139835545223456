import * as React from "react";
import Layout from "../components/layout";
import * as styles from "./schedule.module.css";

import img from "../images/rings.png";

const InfoPage = () => {
    return (
        <Layout>
            <div className={styles.container}>
                <div className={styles.infoContainer}>
                    <div className={styles.header}>Info</div>
                    <div className={styles.img}>
                        <img alt="Illustration" src={img} />
                    </div>
                    <div>
                        <div className={styles.day}>
                            <div className={styles.dayTitle}>
                                Saturday 25th May
                            </div>
                            <div className={styles.dayItem}>
                            <div className={styles.time}> 2.30PM </div>
                            Service at St. John the Baptist
                            <br />
                            Kirdford RH14 0LU
                            </div>
                            <div className={styles.dayItem}>
                                <div className={styles.time}> 4.30PM </div>
                                Reception at Mackerel's Common
                                <br />
                                Kirdford RH14 0JU
                            </div>
                            <div className={styles.dayItem}>
                                <div className={styles.time}> 1AM </div>
                                Last dance
                            </div>
                        </div>
                        <div className={styles.day}>
                            <div className={styles.dayTitle}>Sunday 26th May</div>
                            <div>
                                <div className={styles.time}>1PM</div>
                                Relaxed and very optional bbq :-)
                                <br />
                                Kirdford RH14 0JU
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.faqContainer}>
                    <div className={styles.headerFAQ}>FAQs</div>
                    <div className={styles.faq}>
                        Where's it happening?
                    </div>
                    <div className={styles.faqAnswer}>
                        The service is at St John the Baptist in Kirdford,{" "}
                        <a target="_blank" href="https://maps.app.goo.gl/rGXmSezZnm7x7xCd6">
                            RH14 0LU
                        </a>
                        <br />
                        what3words: <a target="_blank" href="https://w3w.co/cashier.glassware.motion">
                            ///cashier.glassware.motion
                        </a>
                        <br /><br />
                        The reception is in the field behind Mackerel's House{" "}
                        <a target="_blank" href="https://maps.app.goo.gl/MAf9eQAwoWtoi6Cv7">
                            RH14 0JU
                        </a>
                        <br />
                        what3words: <a target="_blank" href="https://w3w.co/saving.global.removers">
                            ///saving.global.removers
                        </a>
                        <br /><br />
                        And the Sunday bbq will be back in the field.
                    </div>
                    <div className={styles.faq}>
                        When should I get there?
                    </div>
                    <div className={styles.faqAnswer}>
                        Please be at the church for 2.30pm, but feel free to join the groom for his last drink of freedom at The Half Moon, opposite the church from 1.30pm.
                    </div>
                    <div className={styles.faq}>
                        How do I get around?
                    </div>
                    <div className={styles.faqAnswer}>
                        Let us know on the <a href="/rsvp">RSVP form</a> if you need transport from Billingshurst station to the church, from the church to the reception, or on Sunday from the bbq back to Billingshurst train station.
                        <br /><br />
                        If you need a taxi, make sure you have booked it before, as taxis are very limited - the earlier you can book this the better!
                        <br /><br />
                        Some taxi numbers:
                        <br /><br />
                        <div>
                            Kevin: <a target="_blank" href="http://www.kbprivatehire.co.uk/">http://www.kbprivatehire.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://www.jakescars.co.uk/">https://www.jakescars.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://aandrcarssussex.co.uk/">https://aandrcarssussex.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://southdowntaxis.co.uk/">https://southdowntaxis.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://www.rudgwickcars.co.uk/">https://www.rudgwickcars.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://westerncars-horsham.co.uk/">https://westerncars-horsham.co.uk/</a>
                        </div>
                        <br />
                        <div>
                            <a target="_blank" href="https://www.haslemereindependenttaxis.com/">https://www.haslemereindependenttaxis.com/</a>
                        </div>
                        <br />
                    </div>
                    <div className={styles.faq}>
                        Dress code?
                    </div>
                    <div className={styles.faqAnswer}>
                        Summer wedding gear - dress your best, but whatever you feel comfortable in! Bridesmaids will be in light green. The reception is in a field, so maybe don’t risk stilettos.
                        <br />
                        The bbq on Sunday will be very relaxed.
                    </div>
                    <div className={styles.faq}>
                        Can I take pictures?
                    </div>
                    <div className={styles.faqAnswer}>
                        No photos during the service please. We have a photographer who will be around the whole day, so we would prefer people to keep their phones away as much as possible :-)
                    </div>
                    <div className={styles.faq}>
                        When should I RSVP by?
                    </div>
                    <div className={styles.faqAnswer}>
                        Please get them in by 1st April.
                        <br /><br />
                        It'd be really helpful if they could be filled out on an individual basis. Thanks!
                    </div>
                    <div className={styles.faq}>
                        Can I bring a plus one?
                    </div>
                    <div className={styles.faqAnswer}>
                        We should have spoken to everyone who has a plus one, but please drop us a message if we’ve missed someone out.
                    </div>
                    <div className={styles.faq}>
                        Can I bring my baby/children?
                    </div>
                    <div className={styles.faqAnswer}>
                        Of course! Just give us a heads up beforehand.
                    </div>
                    <div className={styles.faq}>
                        Do you have a wedding list?
                    </div>
                    <div className={styles.faqAnswer}>
                        For those who are able to, we are accepting donations to a couple of charities very close to our hearts.
                        More info <a target="_blank" href="https://givewheel.com/fundraising/2147/bebe-charlie-wedding/">here</a>
                    </div>
                    <div className={styles.faq}>
                        What’s happening on the Sunday?
                    </div>
                    <div className={styles.faqAnswer}>
                        For those who fancy some hair of the dog and a debrief, there’ll be a very relaxed bbq back in the field around 1pm.
                        We’d love to see you there but understand if you need to head back home. Just let us know in the <a href="/rsvp">RSVP form</a> if you’ll be there!
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default InfoPage;

export const Head = () => <title>Schedule | Bebe and Charlie</title>;
