// extracted by mini-css-extract-plugin
export var day = "schedule-module--day--BS5uU";
export var dayItem = "schedule-module--dayItem--ldZ1w";
export var dayTitle = "schedule-module--dayTitle--++VXh";
export var faq = "schedule-module--faq--+PxM2";
export var faqAnswer = "schedule-module--faqAnswer--JBJll";
export var faqContainer = "schedule-module--faqContainer--4Ugjm";
export var header = "schedule-module--header--zQRVM";
export var headerFAQ = "schedule-module--headerFAQ--9fyD+";
export var img = "schedule-module--img--bvEku";
export var infoContainer = "schedule-module--infoContainer--smzTi";
export var time = "schedule-module--time--fjmxv";